import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import { Row, Col, Dropdown } from "antd";
import { Avatar } from "antd";

const Header = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const logout = useLogout();

  // useEffect(() => {
  //   // Initial dispatch to set the current time
  //   dispatch(updateTime());

  //   const interval = setInterval(() => {
  //     dispatch(updateTime());
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [dispatch]);

  // const selectedPuja = useSelector((state) => state.selectedPujas);
  // console.log(selectedPuja);
  // const profileDetails = useSelector((state) => state.userProfile);
  // console.log(profileDetails);
  // const timeDetails = useSelector((state) => state.time);
  // console.log(timeDetails);

  const items = [
    {
      label: (
        <div
          className="logout-btn-container"
          // onClick={logout}
          style={{ cursor: "pointer" }}
        >
          <button className="logout-btn" onClick={clearLocalStorage}>
            Log out
          </button>
        </div>
      ),
      key: "1",
    },
  ];
  let image = null;

  // Function to clear local storage
  function clearLocalStorage() {
    localStorage.clear();
    // navigate("/login");
    window.location.reload(false);
    // Optionally, you can add a confirmation dialog or message to inform the user
    console.log("Local storage cleared successfully.");
    
  }

  return (
    <header className="header">
      <div className="header-content">
        {/* <h4>{selectedPuja}</h4> */}
        <div className="w-full flex justify-between items-center">
          <div className="name">
            <h6>Admin</h6>
          </div>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className="avatar-container">
              {image ? (
                <Avatar className="" src={Image} size={50} />
              ) : (
                <Avatar
                  shape="circle"
                  size={40}
                  style={{
                    backgroundColor: "#6750A4",
                    fontWeight: 600,
                    fontSize: 17,
                  }}
                >
                  {"A"}
                </Avatar>
              )}
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
