import "./App.css";
import Index from "./routes";
import "./input.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "./components/SideBar/Sidebar";
import Header from "./components/Header/Header";
import Hospitalshifting from "./routes/main/Hospital Shifting/hospitalshifting";
import Officeshifting from "./routes/main/Office shifting/officeshifting";
import Houseshifting from "./routes/main/House shifting/houseshifting";
import Transportation from "./routes/main/Transportation/Transportation";
import Dashboard from "./routes/main/Dashboard/Dashboard";
import Leads from "./routes/main/leads/leads";
const { Content } = Layout;

function App() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <Header />
        <Layout>
          <Content style={{ margin: "16px", overflowY: "auto" }}>
            <div
              className="layout-content-section"
              style={{
                height: "calc(100vh - 64px - 53px)",
                overflowY: "auto",
              }}
            >
              <Routes>
                {/* / path to redirect to home */}
                <Route path="/*" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Dashboard />} />
                <Route
                  path="/hospitalswifting"
                  element={<Hospitalshifting />}
                />

                <Route path="/OfficeShifting" element={<Officeshifting />} />
                <Route path="/houseshifting" element={<Houseshifting />} />
                <Route path="/transportation" element={<Transportation />} />
                <Route path="/leads" element={<Leads />} />
              </Routes>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default App;
