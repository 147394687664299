import React, { useEffect, useState } from "react";
import { Spin } from "antd";

const Hospitalshifting = () => {
  const [serviceData, setServiceData] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setPostLoading(true);
      setErrorMessage("");
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found");
          setErrorMessage("Authentication token is missing.");
          setPostLoading(false);
          return;
        }

        const response = await fetch(
          "https://api.krpackers.com/api/kr-form/getUserData",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              type: "hospital",
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setServiceData(data.data);
        } else if (response.status === 400) {
          setErrorMessage("Bad Request: Please check the input.");
        } else {
          console.error("Error fetching data:", response.status);
          setErrorMessage("An unexpected error occurred.");
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("Failed to fetch data. Please try again later.");
      } finally {
        setPostLoading(false);
      }
    };

    fetchData();
  }, []);

  const parseDate = (createdAt) => {
    const date = new Date(createdAt);
    const indianDate = date
      .toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join("-"); // replace "/" with "-"
    return indianDate;
  };

  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden  w-full">
      <div className="p-6">
        {postLoading ? (
          <div className="flex justify-center items-center">
            <Spin size="small" />
          </div>
        ) : errorMessage ? (
          <div className="text-center my-4 text-red-500">{errorMessage}</div>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Hospital shifting Service Details
            </h2>
            {serviceData.length > 0 ? (
              <table className="min-w-full table-auto border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      order Id
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Name
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Email
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Phone Number
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Appointment Date
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Start Location
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      End Location
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceData.map((service, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.orderId}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.email}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.phoneNumber}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.date || "N/A"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.startLocation}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {service.endLocation}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {parseDate(service.createdAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-4">No service data available</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Hospitalshifting;
