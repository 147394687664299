const BASE_URL = "https://api.krpackers.com";

// Function to make a GET request
export const getApiData = async (endpoint, useAuth) => {
  const token = localStorage.getItem("admin");
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Content-Type",
    Authorization: `Bearer ${token}`,
  };
  if (useAuth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return { data: data, loading: false };
    } else {
      const errorData = await response.json();
      console.error("Error fetching data:", errorData);
      throw errorData;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to make a POST request
export const postApiData = async (endpoint, data) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      return { data: responseData, loading: false };
    } else {
      const errorData = await response.json();
      console.error("Error posting data:", errorData);
      throw { error: errorData, loading: false };
    }
  } catch (error) {
    console.error("Error posting data:", error);
    throw { error, loading: false };
  }
};

// Function to make a POST request using Authorization
export const postAuthApiData = async (endpoint, data) => {
  const url = `${BASE_URL}/${endpoint}`;
  const token = localStorage.getItem("admin");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Headers": "Content-Type",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      return { data: responseData, loading: false };
    } else {
      const errorData = await response.json();
      console.error("Error posting data:", errorData);
      throw { error: errorData, loading: false };
    }
  } catch (error) {
    console.error("Error posting data:", error);
    throw { error, loading: false };
  }
};

// Function to make a PUT request
export const putAuthApiData = async (endpoint, data) => {
  const url = `${BASE_URL}/${endpoint}`;
  const token = localStorage.getItem("admin");

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      return { data: responseData, loading: false };
    } else {
      const errorData = await response.json();
      console.error("Error posting data:", errorData);
      throw { error: errorData, loading: false };
    }
  } catch (error) {
    console.error("Error posting data:", error);
    throw { error, loading: false };
  }
};

// Function to make a DELETE request
export const deleteApiData = async (endpoint, data) => {
  const url = `${BASE_URL}/${endpoint}`;
  const token = localStorage.getItem("admin");

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log(response);
      // Status code is in the range 200-299
      const responseData = await response.json();
      console.log(responseData);
      return { data: responseData };
    } else {
      // Handle non-success status code (e.g., 400 Bad Request)
      const errorData = await response.json();
      console.error("Error deleting data:", errorData);
      throw errorData;
    }
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};
