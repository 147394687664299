import React, { useState, useEffect } from "react";
import { getApiData } from "../../../Api/apiService";

const Leads = () => {
  const [leadsData, setLeadsData] = useState([]);
  const [postLoading, setPostLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchLeadsData = async () => {
      setPostLoading(true);
      try {
        const response = await getApiData("api/getLeads");
        setPostLoading(false);

        // if (response && response.status === 200) {
        setLeadsData(response.data);
        // } else if (response && response.status === 400) {
        //   setErrorMessage("Bad request. Please check the request data.");
        // } else {
        //   setErrorMessage("Something went wrong. Please try again later.");
        // }
      } catch (error) {
        setPostLoading(false);
        setErrorMessage(error.message || "Error fetching the leads data.");
        console.error("Error fetching the leads:", error);
      }
    };

    fetchLeadsData();
  }, []);

  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden w-full">
      <div className="p-6">
        {postLoading ? (
          <div className="flex justify-center items-center">
            <div className="loader">Loading...</div>{" "}
            {/* Replace with your spinner */}
          </div>
        ) : errorMessage ? (
          <div className="text-center my-4 text-red-500">{errorMessage}</div>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Leads Data
            </h2>
            {leadsData.length > 0 ? (
              <table className="min-w-full table-auto border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Name
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Phone Number
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Transport Type
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Subject
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Message
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Status
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leadsData.map((lead, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.phoneNumber}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.transportType}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.subject}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.message}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {lead.status ? "Completed" : "Pending"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {new Date(lead.createdAt).toLocaleDateString() || "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-4">No leads data available</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Leads;

