import React from 'react';
import CountUp from 'react-countup';

const Dashboard = () => {
  return (
    <div className="flex justify-around py-10">
      {/* Services Card */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-64 text-center">
        <h2 className="text-xl font-bold mb-4">Services</h2>
        <p className="text-4xl font-semibold text-blue-500">
          <CountUp end={256} duration={2.5} />
        </p>
      </div>

      {/* Transportation Card */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-64 text-center">
        <h2 className="text-xl font-bold mb-4">Transportation</h2>
        <p className="text-4xl font-semibold text-green-500">
          <CountUp end={142} duration={2.5} />
        </p>
      </div>

      {/* Completed Card */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-64 text-center">
        <h2 className="text-xl font-bold mb-4">Completed</h2>
        <p className="text-4xl font-semibold text-red-500">
          <CountUp end={340} duration={2.5} />
        </p>
      </div>

      {/* In Progress Card */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-64 text-center">
        <h2 className="text-xl font-bold mb-4">In Progress</h2>
        <p className="text-4xl font-semibold text-yellow-500">
          <CountUp end={58} duration={2.5} />
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
