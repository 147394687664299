import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import { Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
const { Sider } = Layout;

const Sidebar = ({ visible, onClose }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider className="sidebar bg-primary" collapsed={collapsed} width={250}>
      <div className="menu-container">
        <div className="top-logo-menu">
          <div className="logo" onClick={toggleCollapsed}>
            <img src="/Logo.png" alt="logo" className="logo w-20 h-20" />
          </div>
          <div className={`${collapsed ? "xtra display" : "xtra"} text-blue`}>
            <h2 className="text-blue text-base">Packers and Movers</h2>
          </div>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[path]} // Use the path as selected key
          className="Sidebar-menu"
        >
          <Menu.Item key="/home" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/home">Dashboard</Link>
            </span>
          </Menu.Item>
          <div className="underline" />
          <Menu.Item key="/houseshifting" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/houseshifting">House Shifting</Link>
            </span>
          </Menu.Item>
          <div className="underline" />
          <Menu.Item key="/OfficeShifting" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/OfficeShifting">Office Shifting</Link>
            </span>
          </Menu.Item>
          <div className="underline" />
          <Menu.Item key="/hospitalswifting" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/hospitalswifting">Hospital Shifting</Link>
            </span>
          </Menu.Item>
          <div className="underline" />
          <Menu.Item key="/transportation" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/transportation">Transportation</Link>
            </span>
          </Menu.Item>
          <div className="underline" />
          <Menu.Item key="/leads" icon={<UserOutlined />}>
            <span style={{ paddingLeft: 9 }}>
              <Link to="/leads">Leads</Link>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
