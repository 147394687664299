import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import App from "./App";

const NextApp = ({ children }) => {
  const token = localStorage.getItem("token");

  console.log("iam here", token);

  return (
    <>
      {!token ? (
        <Routes>
          {/* any route if token null redirect to login */}
          <Route path="/*" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <App />
      )}
    </>
  );
};

export default NextApp;
