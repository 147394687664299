import React from "react";

const Transportation = () => {
  const serviceData = [
    {
      name: "John Doe",
      email: "john.doe@example.com",
      phoneNumber: "123-456-7890",
      appointmentDate: "2024-09-10",
      address: "123 Main St",
      permanentAddress: "456 Secondary St",
      typeOfBooking: "House shifting",
      flatAddress: "Apt 101",
    },
    {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      phoneNumber: "987-654-3210",
      appointmentDate: "2024-09-15",
      address: "789 Pine St",
      permanentAddress: "101 Maple St",
      typeOfBooking: "Office relocation",
      flatAddress: "Suite 202",
    },
    {
      name: "Robert Johnson",
      email: "robert.johnson@example.com",
      phoneNumber: "555-123-4567",
      appointmentDate: "2024-09-18",
      address: "111 Birch Ave",
      permanentAddress: "222 Cedar Ln",
      typeOfBooking: "Warehouse shifting",
      flatAddress: "Unit 303",
    },
  ];
  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden  w-full">
      <div className="p-6">
        <h1> Coming Soon</h1>
      </div>
    </div>
  );
};

export default Transportation;
