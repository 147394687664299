import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import logo from "/Logo.png";

const Login = () => {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const { email, password } = values;

    try {
      const response = await fetch("https://api.krpackers.com/adminLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.responseCode === 200) {
        setErrMsg("");
        localStorage.setItem("token", data.token); // Store the token
        navigate("/home", { replace: true }); // Redirect to home
        window.location.reload(false);
      } else if (data.responseCode === 400) {
        setErrMsg("Invalid email or password. Please try again.");
        console.log("test");
      } else {
        setErrMsg("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrMsg("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 w-full">
      <div className="bg-white p-10 shadow-lg rounded-lg w-full max-w-md">
        <div className="text-center mb-6">
          <img src="/Logo.png" alt="Logo" className="mx-auto w-20 h-20" />
          <h1 className="text-2xl font-bold text-gray-700 mt-4">
            KR-Logistics-Admin
          </h1>
        </div>
        {errMsg && (
          <div className="text-center text-red-500 mb-4">{errMsg}</div>
        )}
        <Form name="login" layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input
              prefix={<MailOutlined className="text-gray-400" />}
              placeholder="Enter your email"
              className="h-10 px-3"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { min: 6, message: "Password must be at least 6 characters!" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Enter your password"
              className="h-10 px-3"
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="bg-[#FFDC39] hover:bg-yellow-500 text-white h-10 rounded-md w-full"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
